import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch';
import bookNoImageEn from '../../templates/_svg/book-no-image.svg';
import bookNoImageEs from '../../templates/_svg/book-no-image-es.svg';

// Initialize Algolia search client
const searchClient = algoliasearch('TXOWF4WIRL', '2f4cbc2a2a6b6b8a8fbde7bfaa68bfb1');

document.addEventListener('DOMContentLoaded', function () {
  const isSpanishSite = document.body.classList.contains('current-site-es');
  const bookNoImage = isSpanishSite ? bookNoImageEs : bookNoImageEn;

  if (!isSpanishSite) {
    // Define variables for pagination and search query
    let currentPage = 0;
    const hitsPerPage = 20;
    const searchQuery = new URLSearchParams(window.location.search).get('q');

    // Initialize Algolia Autocomplete
    autocomplete({
      container: '#autocomplete',
      placeholder: 'Search for episodes',
      getSources({ query }) {
        return [
          {
            sourceId: 'products',
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: 'newbooksnetwork',
                    query,
                    params: {
                      hitsPerPage: 10,
                      attributesToSnippet: ['title:10', 'description:35'],
                      snippetEllipsisText: '…',
                    },
                  },
                ],
              });
            },
            templates: {
              item({ item, components, html }) {
                return html`
                  <a class="flex w-full items-center pt-4" href="${item.url}">
                      <div class="w-full">
                          <h4 class="font-head px-4">${components.Highlight({ hit: item, attribute: 'title' })}</h4>
                      </div>
                  </a>`;
              },
            },
          },
        ];
      },
    });

    const autocompleteInput = document.querySelector('#autocomplete .aa-Input');

    // Fetch initial results if there's a query
    if (searchQuery) {
      fetchAndDisplayResults(searchQuery);
    }

    // Function to fetch and display search results from Algolia
    function fetchAndDisplayResults(query, append = false) {
      searchClient.initIndex('newbooksnetwork').search(query, {
        page: currentPage,
        hitsPerPage: hitsPerPage,
      }).then(({ hits, nbHits }) => {
        const resultsContainer = document.querySelector('#search-results');
        const hitCountElement = document.querySelector('#hit-count');

        if (!append) {
          resultsContainer.innerHTML = ''; // Clear existing results if not appending
        }

        hitCountElement.textContent = nbHits.toLocaleString("en-US");

        hits.forEach(hit => {
          console.log(hit)
          const episodeElement = document.createElement('div');
          episodeElement.classList.add('c-episode-card', 'c-search-result-card');

          let bookCoverUrl = hit.bookCover && isImageUrl(hit.bookCover) ? hit.bookCover : null;
          if (bookCoverUrl && bookCoverUrl.startsWith('//')) {
            bookCoverUrl = 'https:' + bookCoverUrl; // Ensure protocol is included
          }
          
          // Description fallback to googleDescription if available
          const description = hit.description || hit.googleDescription || '';

          // Clean up description, strip HTML tags, and truncate to 6-8 lines of text at most viewport sizes.
          const tempElement = document.createElement('div');
          tempElement.innerHTML = description;
          let cleanedText = tempElement.textContent || tempElement.innerText || '';

          const characterLimit = 400;

          if (cleanedText.length > characterLimit) {
              let lastSpaceIndex = cleanedText.lastIndexOf(' ', characterLimit);
              cleanedText = cleanedText.slice(0, lastSpaceIndex === -1 ? characterLimit : lastSpaceIndex).trim();
          }

          if (cleanedText.endsWith(',')) {
              cleanedText = cleanedText.slice(0, cleanedText.lastIndexOf(',')).trim();
          }

          if (description.length > cleanedText.length) cleanedText += '...';    

          episodeElement.innerHTML = `
              <div class="c-episode-card__image">
                ${bookCoverUrl ? 
                  `<img src="${bookCoverUrl}" alt="${hit.bookTitle}">` :
                  bookNoImage
                }
              </div>
              <div class="c-episode-card__content space-y-3">
                <h3 class="text-xl font-head font-medium leading-5">
                  <a href="${hit.url}">${hit.bookTitle}</a>
                </h3>
                ${hit.bookSubtitle ? `<p class="text-md font-normal mt-1 c-episode-card__content__subtitle">${hit.bookSubtitle}</p>` : ''}
                <div class="c-episode-card__content-bottom">
                  <div class="space-y-1">
                    ${hit.bookAuthor ? `<div class="text-base font-normal leading-4">${hit.bookAuthor}</div>` : ''}
                    ${hit.publisher ? `<div class="text-base font-normal text-gray-700 leading-4">Published by ${hit.publisher}</div>` : ''}
                    ${hit.yearPublished ? `<div class="text-base font-normal text-gray-700 leading-4">${hit.yearPublished}</div>` : ''}
                  </div>
                </div>
                <div class="c-episode-card__description">${cleanedText}</div>
              </div>
          `;

          if (bookCoverUrl) {
            const img = episodeElement.querySelector('img');
            img.onerror = function() {
              this.onerror = null;
              const imgContainer = this.parentElement;
              imgContainer.innerHTML = bookNoImage;
            };
          }

          resultsContainer.appendChild(episodeElement);
        });
      });
    }

    // Event listeners for autocomplete input, form submission, and search button click
    if (autocompleteInput) {
      autocompleteInput.addEventListener('keypress', function(event) {
        if (event.key === 'Enter') {
          event.preventDefault();
          const query = encodeURIComponent(autocompleteInput.value);
          window.location.href = `/search?q=${query}`;
        }
      });
    }

    // Handle form submission
    const searchForm = document.querySelector('.c-search--eng-desktop');
    if (searchForm) {
      searchForm.addEventListener('submit', function(event) {
        event.preventDefault();
        const query = autocompleteInput ? encodeURIComponent(autocompleteInput.value) : '';
        window.location.href = `/search?q=${query}`;
      });
    }

    const searchButton = document.querySelector('#autocomplete .aa-SubmitButton');
    if (searchButton) {
      searchButton.addEventListener('click', function(event) {
        event.preventDefault();
        const query = autocompleteInput ? encodeURIComponent(autocompleteInput.value) : '';
        window.location.href = `/search?q=${query}`;
      });
    }

    // Load more functionality
    const loadMoreButton = document.querySelector('#load-more');
    if (loadMoreButton) {
      loadMoreButton.addEventListener('click', function () {
        currentPage++; // Increment the page
        fetchAndDisplayResults(searchQuery, true); // Fetch the next set of results and append them
      });
    }
  }
});

// Helper function to check if a string looks like a URL of an image
function isImageUrl(str) {
  return (str.startsWith('http://') || str.startsWith('https://') || str.startsWith('//')) && str.match(/\.(jpeg|jpg|gif|png)$/) != null;
}